import "./Home.scss";
import Button from "../../Components/Button/Button";
import Services from "../../Components/Services/Services";
import ImageCarousal from "../../Components/ImageCarousal/ImageCarousal.js"

export default function Home() {
	return (
		<div className="home">
			<div className="home__hero">
				<h1 className="home__title">Alif's Royal Kitchen <br/><span className="home__span">& Lounge</span></h1>
				<h2 className="home__subtitle">Bangladeshi Food & Cuisine</h2>
			</div>
			<div className="home__main">
				<h2 className="home__main-title">
					Edmonton's #1 Bangladeshi Restaurant
				</h2>
				<h3 className="home__introduction">
					Welcome to our cozy corner of culinary delight, where each dish tells
					a story of tradition and taste. Proudly halal, locally owned, and
					passionately run by the members of Edmonton's Bengali community for everyone to enjoy,
					we invite you to savor the authentic flavors of our heritage with
					every bite.
				</h3>
				<ImageCarousal/>
				<Services />
				<Button />
			</div>
		</div>
	);
}

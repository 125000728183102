import React from "react";
import "./Footer.scss";
import { NavLink } from "react-router-dom";

function Footer() {
	return (
		<div className="footer">
			<div className="footer_top">
				<div className="footer_column">
					<NavLink to="/" className="footer_link">
						<h4 className="footer_logo">Alif's Royal Kitchen</h4>
					</NavLink>
					<div className="footer_columns">
						<div className="footer_open">
							<p className="footer_days">Monday-Sunday</p>
							<p className="footer_hours">11:30am-9pm</p>
						</div>
					</div>
				</div>
				<div className="footer_column">
					<h4 className="footer_header">Find Us</h4>

					<p>
						3811 99 St NW
						<br /> Edmonton, AB
						<br /> T6E 6J1
						<br />
						<br />{" "}
						<a href="mailto: alifroyalkitchen@gmail.com">alifroyalkitchen@gmail.com</a>
						<br /> <a href="tel:+17809992394">780-999-2394</a>
					</p>
				</div>
				<div className="footer_column">
					<h4 className="footer_header">Site Map</h4>
					<div className="footer_columns">
						<div className="footer_column-one">
							<NavLink to="/">Home</NavLink>
							<NavLink to="/menu">Menu</NavLink>
							<NavLink to="/contact">Contact Us</NavLink>
						</div>
					</div>
				</div>
			</div>
			<div className="footer_bottom">
				<h4>
					&copy; {new Date().getFullYear()} <span>Created By Sharon Lee</span> All
					rights reserved.
				</h4>
			</div>
		</div>
	);
}

export default Footer;

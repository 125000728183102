import Header from "./Components/Header/Header";
import Home from "./Pages/Home/Home.js";
import MobileNavbar from "./Components/Header/MobileNavBar";
import Menu from "./Pages/Menu/Menu";
import ContactUs from "./Pages/Contact/Contact";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import ScrollToTop from "./Components/ScrollToTop";

function App() {
	return (
		<div className="App">

			<BrowserRouter>
			<ScrollToTop/>
			<Header/>
			<MobileNavbar/>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/menu" element={<Menu/>} />
          <Route path="/contact" element={<ContactUs/>} />
				</Routes>
				<Footer/>
			</BrowserRouter>
		</div>
	);
}

export default App;

import React from "react";
import "./Menu.scss";
import { useInView } from "react-intersection-observer";
import tandooriChickenImg from "../../assets/images/chicken.jpg";
import beefTehari from "../../assets/images/BeefTehari.jpg";
import RuiFish from "../../assets/images/RuiFish.jpg";
import ShorsheIlish from "../../assets/images/ShorsheIlish.jpg";
import chickenPulaoImg from "../../assets/images/chickenroast.jpg";
import Mutton from "../../assets/images/mutton.jpg";

export default function Menu() {
  const featuredMenu = {
    FeaturedMenu: [
      {
        Item: "Beef Tehari",
        Price: 12.99,
        Description:
          "Aromatic rice infused with tender beef pieces, seasoned with a blend of traditional spices for a hearty and flavorful Bangladeshi delight.",
        Image: beefTehari,
      },
      {
        Item: "Mutton Kachhi",
        Price: 22.99,
        Description:
          "Succulent mutton layered with fragrant basmati rice, marinated in a rich blend of spices and slow-cooked to perfection for an exquisite taste.",
        Image: Mutton,
      },
      {
        Item: "Shahi Chicken & Pulao",
        Price: 13.99,
        Description:
          "Royal chicken cooked in a creamy, spice-laden sauce, served alongside fluffy pulao rice, offering a dish fit for a king.",
        Image: chickenPulaoImg,
      },
      {
        Item: "Tandoori Chicken",
        Price: 9.99,
        Description:
          "Savor the essence of Bengal with our Tandoori Chicken. Tender chicken marinated in yogurt and spices, then cooked to perfection in our tandoor oven. Charred on the outside, juicy on the inside.",
        Image: tandooriChickenImg,
      },
      {
        Item: "Shorshe Ilish",
        Price: 13.99,
        Description:
          "Hilsa fish in a strong mustard sauce, combining the richness of mustard with the tenderness of fish for a spicy, flavorful experience.",
        Image: ShorsheIlish,
      },
      {
        Item: "Rohu Fish Dopiaza",
        Price: 7.99,
        Description:
          "Rohu fish cooked with a generous amount of onions and a blend of spices, creating a harmonious mix of flavors and textures, a testament to traditional cooking.",
        Image: RuiFish,
      },
    ],
  };

  const regularMenu = {
    Biryani: [
      {
        Item: "Authentic Bangladeshi Mutton Kachhi",
        Price: 22.99,
        Description: "Tender lamb in a creamy, spiced sauce",
        SpiceLevel: 0,
      },
      {
        Item: "Beef Tehari",
        Price: 12.99,
        Description: "Seasoned spicy beef and aromatic rice",
        SpiceLevel: 0,
      },
      {
        Item: "Chicken Biryani",
        Price: 11.99,
        Description: "Rich marinated chicken and fragrant rice",
        SpiceLevel: 0,
      },

      {
        Item: "Shahi Chicken Roast with Pulao",
        Price: 13.99,
        Description:
          "Flavourful roasted chicken with spices and yogurt, and rice",
        SpiceLevel: 0,
      },
      {
        Item: "Shahi Chicken Roast",
        Price: 8.99,
        Description: "Flavourful roasted chicken with spices and yogurt.",
        SpiceLevel: 0,
      },
      {
        Item: "Ilish Pulao",
        Price: 15.99,
        Description: "Flavourful roasted fish with spices and yogurt.",
        SpiceLevel: 2,
      },
    ],
    MeatCurries: [
      {
        Item: "Beef Curry",
        Price: 12.99,
        Description: "Slow-cooked beef in a rich, spiced curry sauce.",
        SpiceLevel: 2,
      },
      {
        Item: "Beef Kalo Bhuna",
        Price: 15.99,
        Description: "Tender beef in a thick, spicy gravy.",
        SpiceLevel: 2,
      },
      {
        Item: "Mutton Rezala",
        Price: 16.99,
        Description: "Slow cooked seasoned goat with cashew paste and saffron.",
        SpiceLevel: 2,
      },
      {
        Item: "Chicken Bhuna",
        Price: 10.99,
        Description: "Slow cooked bone-in chicken with aromatics.",
        SpiceLevel: 1,
      },
      {
        Item: "Butter Chicken Korma",
        Price: 14.99,
        Description: "Slow cooked buttery chicken with aromatics.",
        SpiceLevel: 1,
      },
    ],
    FishCurries: [
      {
        Item: "Shorshe Ilish",
        Price: 13.99,
        Description: "Hilsa fish with onions in a spicy, flavorful sauce",
        SpiceLevel: 2,
      },
      {
        Item: "Rohu Fish Dopiaza",
        Price: 7.99,
        Description:
          "Delicate fish simmered in a rich, tangy onion Bengali sauce.",
        SpiceLevel: 2,
      },
      {
        Item: "Prawn Malai Curry",
        Price: 14.99,
        Description: "Shrimp cooked with onions, spices, and sweetness",
        SpiceLevel: 0,
      },
      {
        Item: "Loitta Shutki Bhuna",
        Price: 14.99,
        Description: "Shuki made from Loitta fish",
        SpiceLevel: 0,
      },
      {
        Item: "Laal Morich Shutki Bhorta",
        Price: 6.99,
        Description: "Shrimp cooked with onions, spices, and sweetness",
        SpiceLevel: 0,
      },
    ],
    Rice: [
      {
        Item: "Plain Rice",
        Price: 3.99,
        Description: "Aromatic seasoned rice",
        SpiceLevel: 0,
      },
      {
        Item: "Plain Pulao",
        Price: 5.99,
        Description: "Fragrant rice cooked with aromatic spices and herbs",
        SpiceLevel: 0,
      },
      {
        Item: "Khichuri",
        Price: 5.99,
        Description:
          "A comforting mix of rice and lentils, seasoned with spices.",
        SpiceLevel: 0,
      },
      {
        Item: "Tandoori Naan",
        Price: 2.99,
        Description: "Cooked in our clay oven",
        SpiceLevel: 0,
      },
    ],
    Vegetables: [
      {
        Item: "Shahi Paneer",
        Price: 12.99,
        Description: "Cooked with onions, yogurt, nuts and seedds",
        SpiceLevel: 1,
      },
      {
        Item: "Mixed Vegetables",
        Price: 8.99,
        Description:
          "A sauteed blend of vegetables, tossed w/ house-made spices",
        SpiceLevel: 1,
      },
      {
        Item: "Aloo Bhorta",
        Price: 4.99,
        Description: "Mashed potatoes mixed w/ fresh herbs and mustard oil.",
        SpiceLevel: 2,
      },
      {
        Item: "Begun Bhorta",
        Price: 5.99,
        Description: "A Bangladeshi side, mashed grilled seasoned eggplant",
        SpiceLevel: 2,
      },
      {
        Item: "Dal",
        Price: 4.99,
        Description:
          "A hearty dish made with lentils, tomatos, onions & spices",
        SpiceLevel: 0,
      },
    ],
    Kabab: [
      {
        Item: "Tandoori Chicken with Naan",
        Price: 12.99,
        Description:
          "Chicken marinated in yogurt and tandoori spices, then roasted in a clay oven. Served with Naan Bread and Raita",
        SpiceLevel: 1,
      },
      {
        Item: "Tandoori Chicken",
        Price: 9.99,
        Description:
          "Chicken marinated in yogurt and tandoori spices, then roasted in a clay oven. Served with Raita",
        SpiceLevel: 1,
      },
      {
        Item: "Beef Boti Kebab with Naan",
        Price: 14.99,
        Description:
          "Juicy, cubed beef seasoned with a blend of herbs and spices. Served with Tandoori Naan & Raita.",
        SpiceLevel: 1,
      },
      {
        Item: "Chicken Tikka Kebab with Naan",
        Price: 14.99,
        Description:
          "Chicken marinated in a rich blend of tikka spices. Served with Tandoori Naan & Raita.",
        SpiceLevel: 1,
      },
    ],
	Appetizers: [ 
		{
			Item: "Samosa/Singara",
			Price: 1.50,
			Description:
			  "Small Crispy Pastry with a delicious and savory potato filling",
			SpiceLevel: 0,
		},
		{
			Item: "Spring Roll",
			Price: 1.50,
			Description:
			  "Crispy roll with a meat and veggie filling",
			SpiceLevel: 0,
		},
		{
			Item: "Chicken Nugget",
			Price: 4.99,
			Description:
			  "4 Piece Crispy Chicken Nugget",
			SpiceLevel: 0,
		},
		{
			Item: "Fries",
			Price: 3.99,
			Description:
			  "Crispy Potato Fries",
			SpiceLevel: 0,
		},
		{
			Item: "Thai Soup",
			Price: 5.99,
			Description:
			  "Thai Soup",
			SpiceLevel: 0,
		},
		{
			Item: "Chatpati",
			Price: 8.99,
			Description:
			  "",
			SpiceLevel: 0,
		},
		{
			Item: "Fuska",
			Price: 8.99,
			Description:
			  "",
			SpiceLevel: 0,
		},
		{
			Item: "Shahi Haleem",
			Price: 7.99,
			Description:
			  "Crispy roll with a meat and veggie filling",
			SpiceLevel: 0,
		},

	],
	
    BangladeshiChinese: [
      {
        Item: "Combo One",
        Price: 15.99,
        Description:
          "Chinese Fried Rice with Crispy Chicken & Chinese Mixed Vegetables",
        SpiceLevel: 0,
      },
      {
        Item: "Combo Two",
        Price: 17.99,
        Description:
          "Chinese Fried Rice with Crispy Chicken & THAI BEEF CHILLI or CHICKEN CHILLI",
        SpiceLevel: 0,
      },
    ],
  };

  const drinkMenu = [
    {
      Item: "Borhani",
      Price: 3.99,
      Description:
        "A traditional refreshing Bengali drink, made with a blend of yogurt and spices",
      SpiceLevel: 1,
    },
    {
      Item: "Barbican",
      Price: 2.99,
      Description: "",
      SpiceLevel: 1,
    },
    {
      Item: "Juice Bottle",
      Price: 2.99,
      Description: "Fruit Juice Assortments",
      SpiceLevel: 1,
    },
    {
      Item: "Mango Lassi",
      Price: 4.99,
      Description:
        "A creamy sweet and spiced fusion of ripe mangoes and yogurt",
      SpiceLevel: 0,
    },
    {
      Item: "Tea",
      Price: 2.99,
      Description: "A traditional Bengali Tea",
      SpiceLevel: 0,
    },
    {
      Item: "Water",
      Price: 1.99,
      Description: "Bottled Water",
      SpiceLevel: 0,
    },
    {
      Item: "Soft Drinks",
      Price: 1.99,
      Description: "Carbonated Assortments",
      SpiceLevel: 0,
    },
  ];

  const childrensMenu = [
    {
      Item: "Chicken Nugget Combo",
      Price: 8.99,
      Description: "Comes with 4pc Nuggets, fries & a soft drink",
      SpiceLevel: 0,
    },
    {
      Item: "Chicken Tender Combo",
      Price: 7.99,
      Description: "Comes with 2pc Tenders, fries & a soft drink",
      SpiceLevel: 0,
    },
    {
      Item: "French Fries",
      Price: 3.99,
      Description: "Crispy on the outside, soft on the inside.",
      SpiceLevel: 0,
    },
  ];

  const MenuItemWithImage = ({ item, imageSrc }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });

    return (
      <div ref={ref} className={`menu-item ${inView ? "is-inView" : ""}`}>
        <MenuItem item={item} />
        {imageSrc && (
          <img src={imageSrc} alt={item.Item} className="menu__image" />
        )}
      </div>
    );
  };

  const MenuItem = ({ item }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });

    const renderChiliPeppers = (level) => {
      const peppers = [];
      for (let i = 0; i < level; i++) {
        peppers.push(
          <span key={i} role="img" aria-label="chili pepper">
            🌶️
          </span>
        );
      }
      return peppers;
    };

    return (
      <li ref={ref} className={`menu__list ${inView ? "is-inView" : ""}`}>
        <div className="menu__list-top">
          <div className="menu__list-left">
            <span className="menu__list-item">{item.Item}</span>
            <span className="menu__list-spice">
              {renderChiliPeppers(item.SpiceLevel || 0)}
            </span>
          </div>

          <span className="menu__list-price">${item.Price}</span>
        </div>
        <p className="menu__list-description">{item.Description}</p>
      </li>
    );
  };

  const FeaturedMenu = ({ menu }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });

    return (
      <div
        ref={ref}
        className={`menu__list menu__feature  ${inView ? "is-inView" : ""}`}
      >
        {featuredMenu.FeaturedMenu.map((menu, index) => (
          <div key={index} style={{ textAlign: "center" }}>
            <img
              src={menu.Image}
              alt={menu.Item}
              style={{
                width: "100%",
                height: "200px",
                objectFit: "cover",
                borderRadius: "15px",
              }}
            />
            <h3 className="menu__featured">
              {menu.Item} - ${menu.Price}
            </h3>
            <p style={{ textAlign: "left" }}>{menu.Description}</p>
          </div>
        ))}
      </div>
    );
  };

  const ServiceList = ({ menuItems, category }) => {
    return (
      <div className="menu__category">
        <h2 className="menu__category-header">{category}</h2>
        <ul className="menu__lists">
          {menuItems.map((item, index) => (
            <MenuItemWithImage key={index} item={item} />
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="menu">
      <div className="menu__hero">
        <h1 className="menu__title">Menu</h1>
        <h3 className="menu__subtitle">Delicious, Halal, and Authentic</h3>
      </div>
      <div className="menu__container">
        <h2 className="menu__category-header">Featured Items</h2>
        <FeaturedMenu />
        {Object.keys(regularMenu).map((category) => (
          <ServiceList
            key={category}
            category={category.replace(/([A-Z])/g, " $1").trim()}
            menuItems={regularMenu[category]}
          />
        ))}

        <ServiceList
          key="Children's Menu"
          category="Children's Menu"
          menuItems={childrensMenu}
        />
        <ServiceList key="Drinks" category="Drinks" menuItems={drinkMenu} />
        <p className="menu__warning">
          *Our Food Items May Contain Nuts. <br />
          Please inform your server if you have any allergies.{" "}
        </p>
      </div>
    </div>
  );
}

import "./Contact.scss";

export default function ContactUs( ) {
	return (
		<div className="contact">
			<h1 className="contact__heading">CONTACT US</h1>
			<div className="contact-us">
				<div className="contact-us__box">
					<h2 className="contact-us__heading">LOCATION</h2>
					<h4 className="contact-us__address">3811 99 St NW</h4>
					<h4 className="contact-us__address">Edmonton, AB T6E 6J1</h4>
					<h4 className="contact-us__address">(780) 999-2394</h4>
				</div>
				<div className="contact-us__box">
					<h2 className="contact-us__heading">BUSINESS HOURS</h2>
					<div className="contact-us__hrs">
						<div className="contact-us__days">
						<h4 className="contact-us__day">Monday-Sunday</h4>

						</div>
						<div className="contact-us__hours">
							<h4 className="contact-us__hour">11:30-9</h4>
						</div>
					</div>
				</div>
				<div className="contact-us__box">
					<h2 className="contact-us__heading">EMAIL US</h2>
					<p className="contact-us__email-link">
						<a href="mailto: alifroyalkitchen@gmail.com">
            alifroyalkitchen@gmail.com
						</a>
					</p>
				</div>
			</div>
		</div>
	);
}


import { NavLink } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import './Button.scss';

export default function Button() {

  return (
    <div className="button">
      <h2 className="button_title">
        Your <TypeAnimation
        sequence={[
          "Wedding",
          1000,
          "Birthday",
          1000,
          "Family Gathering",
          1000,
          "Corporate Event",
          1000,
          "Celebration",
          1000,
        ]}
        speed={1}
        repeat={Infinity}
        style={{ fontSize: '1em', color:"white" }}
      />, Our Catering. <br/>
      Contact Us for More Information
      </h2>
      <div className="button_bottom">
        <div className="button_btn-container">
          <NavLink to="/contact" className="button_link button_button">
            <h3 className="button_btn">Contact Us</h3>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
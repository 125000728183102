import { NavLink } from "react-router-dom";
import "./Header.scss";
import logo from "../../assets/images/logo.png";

export default function Header() {
	return (
		<nav className="nav">
			<div className="header">
				<div className="header_left">
					<NavLink to="/" className="header_link header_logo">
          <img
          className="header_image"
							src={logo}

              />
					</NavLink>
				</div>

				<div className="header_center">
					<NavLink to="/" className="header_link">
						<span className="header_item">Home</span>
					</NavLink>
					<NavLink to="/Menu" className="header_link">
						<span className="header_item">Menu</span>
					</NavLink>
					<div className="header_btn-container">
						<NavLink to="/contact" className="header_link header_button">
							<span className="header_book">Contact</span>
						</NavLink>
					</div>
				</div>
			</div>
		</nav>
	);
}

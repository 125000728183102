import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./MobileNavbar.module.css";
import logo from "../../assets/images/logo.png";

function MobileNavbar() {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [visible, setVisible] = useState(window.innerWidth < 1280);

	useEffect(() => {
		const handleResize = () => setVisible(window.innerWidth < 1280);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const closeDrawer = () => setDrawerOpen(false);

	const siteMap = [
		{ to: "/", label: "Home" },
		{ to: "/menu", label: "Menu" },
		{ to: "/contact", label: "Contact" },
	];

	const renderLinks = (links) =>
		links.map((link) => (
			<ListItem
				button
				key={link.label}
				component={Link}
				to={link.to}
				onClick={closeDrawer}
				className={styles.listItem} // Apply custom class for ListItem
			>
				<ListItemText
					primary={link.label}
					primaryTypographyProps={{
						style: { textAlign: "center", fontSize: "1.25rem" },
					}} // Apply inline styles directly
				/>
			</ListItem>
		));

	if (!visible) return null;

	return (
		<div>
			<AppBar position="fixed" style={{ width: "100%" }}>
				<Toolbar className={styles.toolbar}>
					<Typography variant="h6" component="h4" className={styles.title}>
						<Link to="/" className={styles.title}>
							<img className={styles.logo} src={logo} />
						</Link>
					</Typography>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="menu"
						onClick={() => setDrawerOpen(true)}
					>
						<MenuIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			{/* Add padding to compensate for the fixed header */}
			<div style={{ paddingTop: "64px" }}>
				<Drawer
					anchor="right"
					open={drawerOpen}
					onClose={closeDrawer}
					PaperProps={{ style: { width: "100vw" } }}
				>
					<div className={styles.top}>
						<IconButton
							edge="end"
							color="inherit"
							onClick={closeDrawer}
							className={styles.closeButton}
						>
							<CloseIcon />
						</IconButton>
					</div>
					<div className={styles.drawerContent}>
						<div className={styles.column}>
							<ListItemText
								primary="Site Map"
								className={styles.columnHeader}
							/>
							{renderLinks(siteMap)}
						</div>
					</div>
					<div className={styles.drawerFooter}>
						<div>
							<a
								href="mailto:alifroyalkitchen@gmail.com"
								className={styles.contactLink}
							>
								alifroyalkitchen@gmail.com
							</a>
							<a href="tel:+17809992394" className={styles.contactLink}>
								(780) 999-2394
							</a>
							<a
								href="https://maps.app.goo.gl/7DfDaTvyDYHjYuqZ6"
								className={styles.contactLink}
							>
								3811 99 St NW, Edmonton, AB T6E 6J1
							</a>
						</div>
					</div>
				</Drawer>
			</div>
		</div>
	);
}

export default MobileNavbar;

import React from 'react';
import './Services.scss'; // Ensure you have this SCSS file in the same directory
import pic from "../../assets/images/pics.jpg";
import catering from "../../assets/images/catering.jpg"
import cater from "../../assets/images/cater.jpg"
import mealfor1 from "../../assets/images/mealfor1.jpg"
import { useInView } from "react-intersection-observer";

// ServiceItem component
const ServiceItem = ({ service, index }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div ref={ref} className={`service ${index % 2 === 0 ? 'service--normal' : 'service--reverse'} ${inView ? "is-inView" : ""}`}>
      <div className="service__image">
        <img src={service.image} alt={service.name} />
      </div>
      <div className="service__content">
        <h3 className="service__name">{service.name}</h3>
        <p className="service__description">{service.description}</p>
      </div>
    </div>
  );
};

const Services = () => {
  const services = [
    {
      name: 'Catering',
      description: 'Offering a range of catering options for events of all sizes. Customize your menu with our authentic dishes.',
      image: cater,
    },
    {
      name: '2nd Floor Rentals',
      description: 'Rent our spacious and elegant second floor for your next event. Perfect for intimate weddings, meetings, and special occasions.',
      image: catering,
    },
    {
      name: 'Weekly Meals',
      description: 'Enjoy a hassle-free week with our weekly meal plans. Delicious, healthy, and conveniently customized for you. Pick up only.',
      image: mealfor1,
    }
  ];

  return (
    <div className="services">
      <h2 className="services__title">Services</h2>
      {services.map((service, index) => (
        <ServiceItem key={index} service={service} index={index} />
      ))}
    </div>
  );
};

export default Services;

import "./ImageCarousal.scss";
import { useState, useEffect } from 'react';
import bang1 from "../../assets/images/bang1.jpg";
import bang2 from "../../assets/images/bang2.jpg";
import bang3 from "../../assets/images/bang3.jpg";
import bang4 from "../../assets/images/bang4.jpg";
import bang5 from "../../assets/images/bang5.jpg";
import bang6 from "../../assets/images/bang6.jpg";
import bang7 from "../../assets/images/bang7.webp";
import bang10 from "../../assets/images/bang10.jpg";


const landmarks = [
  { src: bang1, label: 'Landmark Smrity Shoudho' },
  { src: bang2, label: 'Shahid Minar' },
  { src: bang3, label: 'Bangladesh Waters' },
  { src: bang4, label: 'Bangladesh Landscape' },
  { src: bang5, label: 'Bangladesh Landscape' },
  { src: bang6, label: 'Bangladesh Parliament Building' },
  { src: bang7, label: 'Bangladesh Market' },
  { src: bang10, label: 'Bangladesh Kitchen' },
];

const Carousel = ({ items = landmarks }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const transitionTime = 3000; // Time in milliseconds for each transition

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
    }, transitionTime);

    return () => clearInterval(interval);
  }, [currentIndex, items.length]);

  const goToImage = (index) => {
    setCurrentIndex(index);
  };

  const goToPrevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
  };

  const goToNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="carousel">
      <div className="carousel__top">
        <div className="carousel__arrow carousel__arrow--prev" onClick={goToPrevImage}>&#10094;</div>
        <div className="carousel-image-container">
          <img
            src={items[currentIndex].src}
            alt={items[currentIndex].label}
            className="carousel__image"
          />
        </div>
        <div className="carousel__arrow carousel__arrow--next" onClick={goToNextImage}>&#10095;</div>
      </div>
      <div className="dots">
        {items.map((_, index) => (
          <button
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToImage(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
